/**
 * Error handling utilities for consistent error parsing and display
 * throughout the application
 */

/**
 * Parse error from API response into a standardized format
 * @param {Object} error - The error object from API response
 * @param {String} defaultMessage - Default message to use if none found
 * @returns {Object} Standardized error object
 */
export const parseApiError = (error, defaultMessage = "An error occurred") => {
  // Create a normalized error object
  let parsedError = {
    message: defaultMessage,
    details: "",
    originalError: error,
    status: null,
  };

  if (!error) return parsedError;

  // Extract HTTP status if available
  if (error.response && error.response.status) {
    parsedError.status = error.response.status;
  }

  // Handle axios error response
  if (error.response && error.response.data) {
    const responseData = error.response.data;

    if (responseData.error) {
      parsedError.message = responseData.error;
    }

    if (responseData.details) {
      parsedError.details = responseData.details;
    }

    // Special handling for common errors
    handleSpecialErrorCases(parsedError, responseData);
  } else if (error.message) {
    parsedError.message = error.message;
  }

  return parsedError;
};

/**
 * Format error key from snake_case or camelCase to Title Case with spaces
 * @param {String} key - The error key to format
 * @returns {String} Formatted key
 */
export const formatErrorKey = (key) => {
  return key
    .replace(/_/g, " ")
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};

/**
 * Handle special error cases with custom formatting
 * @param {Object} parsedError - The error object being built
 * @param {Object} responseData - The API response data
 */
const handleSpecialErrorCases = (parsedError, responseData) => {
  // Missing columns error
  if (responseData.error && responseData.error.includes("Missing required columns")) {
    parsedError.message = "File is missing required columns";

    // Extract the column names from the error message
    const match = responseData.error.match(/Missing required columns: (.+)/);
    if (match && match[1]) {
      const missingColumns = match[1].split(", ");
      parsedError.details = missingColumns;
    }
  }

  // Empty file error
  if (
    responseData.error &&
    (responseData.error.includes("Excel file is empty") ||
      responseData.error.includes("No data found in Excel file"))
  ) {
    parsedError.message = "The uploaded file contains no data";
    parsedError.details = "Please ensure the file contains valid data in the first sheet.";
  }

  // Date format errors
  if (responseData.error && responseData.error.includes("Invalid date")) {
    parsedError.message = "The file contains invalid date formats";
    // Try to extract which field has the invalid date
    const match = responseData.error.match(/Invalid date in (.+?):/);
    if (match && match[1]) {
      parsedError.details = `Please correct the date format in the "${match[1]}" column.`;
    } else {
      parsedError.details = "Please ensure all date fields use the format YYYY-MM-DD.";
    }
  }
};

/**
 * Get appropriate error message based on HTTP status code
 * @param {Number} status - HTTP status code
 * @param {Object} data - Response data object
 * @returns {Object} Error message and details
 */
export const getStatusBasedErrorMessage = (status, data = {}) => {
  switch (status) {
    case 400:
      // Bad request - typically validation errors
      return {
        message: data.error || "The file could not be processed due to validation errors",
        details: data.details || "",
      };
    case 409:
      // Conflict - typically for duplicate month/year
      return {
        message: data.error || "An upload already exists for this month and year",
        details: "Please use the replace function instead",
      };
    case 413:
      // Payload too large
      return {
        message: "File is too large",
        details: "Please upload a file smaller than 10MB",
      };
    case 415:
      // Unsupported media type
      return {
        message: "Unsupported file format",
        details: "Please upload an Excel file (.xlsx, .xls) or CSV file",
      };
    case 500:
      // Server error - show more generic message
      return {
        message: "Server error while processing your file",
        details: data.details || "Please try again later or contact support",
      };
    case 401:
      return {
        message: "Authentication required",
        details: "Your session may have expired. Please login again.",
      };
    case 403:
      return {
        message: "Permission denied",
        details: "You do not have permission to perform this action.",
      };
    case 404:
      return {
        message: data.error || "Resource not found",
        details: data.details || "",
      };
    default:
      // Return the original error or a generic message
      return {
        message: data.error || "An error occurred",
        details: data.details || "",
      };
  }
};

export default {
  parseApiError,
  formatErrorKey,
  getStatusBasedErrorMessage,
};
