<template>
  <div>
    <v-dialog
      v-model="formOpened"
      persistent
      width="40%"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Upload File</span>
        </v-card-title>
        <v-card-text>

          <v-layout justify-center>
            <v-form ref="newUploadForm" @submit.prevent>
              <v-flex xs12>
                <v-layout row wrap>
                  <v-flex xs12>
                    <span>Please specify the month and year as well as the file</span>
                    <br/>
                    <span class="warning-text">Warning: Selecting an existing month/year will overwrite the data.</span>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-menu
                      ref="menu"
                      v-model="dateMenu"
                      :close-on-content-click="false"
                      :return-value.sync="reportDate"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="reportDate"
                          label="Report Month"
                          prepend-icon="calendar"
                          readonly
                          v-on="on"
                          :error-messages="dateError"
                        ></v-text-field>
                      </template>
                      <v-date-picker 
                        v-model="reportDate" 
                        no-title 
                        scrollable 
                        type="month"
                        :max="maxDate"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="dateMenu = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="$refs.menu.save(reportDate)">OK</v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-flex>
                </v-layout>
                <v-layout row>
                  <v-flex xs12>
                    <v-text-field
                      label="Choose source file"
                      @click="$refs.fileInput.click()"
                      v-model="uploadFileName"
                      prepend-icon="attach_file"
                      readonly
                      :error-messages="fileError"
                    ></v-text-field>
                    <input
                      ref="fileInput"
                      type="file"
                      style="display: none"
                      @change="handleFileUpload"
                      accept=".xlsx,.xls,.csv"
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-form>
          </v-layout>
          
          <!-- New upload progress indicator -->
          <v-layout row v-if="uploadProgress > 0 && uploadProgress < 100">
            <v-flex xs12>
              <p class="text-center mb-0">Processing file... Please wait</p>
              <v-progress-linear
                :value="uploadProgress"
                color="primary"
                height="25"
                striped
              >
                <template v-slot:default>
                  <strong>{{ Math.ceil(uploadProgress) }}%</strong>
                </template>
              </v-progress-linear>
            </v-flex>
          </v-layout>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
            text
            color="blue darken-1" 
            :loading="dataLoading"
            :disabled="dataLoading"
            @click="closeDialog"
          >
            Cancel
          </v-btn>
          <v-btn 
            text
            color="blue darken-1"
            :loading="dataLoading"
            :disabled="dataLoading || !isValid"
            @click="uploadNewFile"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <data-loading :visible="dataLoading"></data-loading>

    <v-dialog v-model="generateReportDialog" max-width="500px">
      <v-card>
        <v-card-title class="py-3 px-4" style="background-color: #f5f5f5;">
          <span class="body-2 font-weight-medium">Generate Credit Score Report</span>
        </v-card-title>
        <v-card-text class="pt-4">
          File uploaded successfully. Would you like to generate a credit score report now?
        </v-card-text>
        <v-card-actions style="background-color: #f5f5f5;">
          <v-spacer></v-spacer>
          <v-btn text color="grey darken-1" @click="closeGenerateReportDialog">Not Now</v-btn>
          <v-btn text color="primary" @click="generateReport" :loading="generating">Yes, Generate Report</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="3000"
    >
      {{ snackbar.text }}
      <v-btn text @click="snackbar.show = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment-timezone";
import creditScoringService from "@/services/creditScoringService";
import ErrorDialog from "@/components/ErrorDialogComponent.vue";

export default {
  name: "NewFileUploadComponent",
  
  components: {
    ErrorDialog
  },
  
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    dataLoading: false,
    dateMenu: false,
    reportDate: moment().add(-1, "months").endOf('month').format("YYYY-MM"),
    uploadFile: null,
    uploadFileName: '',
    error: {
      show: false,
      message: '',
      details: ''
    },
    errorDialog: {
      show: false,
      title: 'Error',
      message: '',
      details: '',
      type: 'error',
      suggestion: '',
      showRetryButton: false,
      showActionButton: false,
      actionButtonText: 'Action',
      actionButtonColor: 'primary'
    },
    dateError: '',
    fileError: '',
    generateReportDialog: false,
    generating: false,
    uploadResponse: null,
    snackbar: {
      show: false,
      text: '',
      color: 'info'
    },
    uploadProgress: 0
  }),

  computed: {
    formOpened: {
      get() {
        return this.visible;
      },
      set(newValue) {
        this.$emit("update:visible", newValue);
      }
    },

    maxDate() {
      return moment().format('YYYY-MM');
    },

    isValid() {
      return this.reportDate && this.uploadFile && !this.dateError && !this.fileError;
    }
  },

  methods: {
    handleDialogAction() {
      if (typeof this.errorDialog.action === 'function') {
        this.errorDialog.action();
      } else {
        console.warn('Action handler called but no action function defined');
      }
    },
    
    handleFileUpload(event) {
      this.fileError = '';
      const file = event.target.files[0];
      
      if (file) {
        // Validate file type
        const allowedTypes = [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.ms-excel',
          'text/csv'
        ];
        
        // Check file extension if MIME type doesn't match
        const fileName = file.name.toLowerCase();
        const validExtension = fileName.endsWith('.xlsx') || 
                             fileName.endsWith('.xls') || 
                             fileName.endsWith('.csv');
        
        if (!allowedTypes.includes(file.type) && !validExtension) {
          this.fileError = 'Please upload an Excel file (.xlsx, .xls) or CSV file';
          this.uploadFile = null;
          this.uploadFileName = '';
          return;
        }

        // Validate file size (10MB max)
        if (file.size > 10 * 1024 * 1024) {
          this.fileError = 'File size should be less than 10MB';
          this.uploadFile = null;
          this.uploadFileName = '';
          return;
        }

        this.uploadFile = file;
        this.uploadFileName = file.name;
      }
    },

    showError(message, details = '') {
      this.showErrorDialog({
        title: 'Validation Error',
        message: message,
        details: details,
        type: 'error',
        showRetryButton: false
      });
    },

    showErrorDialog(options) {
      const errorTitle = options.title || 'Error';
      const errorMessage = options.message || 'An error occurred';
      const errorDetails = options.details || '';
      const errorType = options.type || 'error';
      const suggestion = options.suggestion || '';
      const showRetryButton = options.showRetryButton || false;
      
      const hasValidAction = typeof options.action === 'function';
      const showActionButton = options.showActionButton && hasValidAction;
      const actionButtonText = options.actionButtonText || 'Action';
      const actionButtonColor = options.actionButtonColor || 'primary';
      
      this.errorDialog = {
        show: true,
        title: errorTitle,
        message: errorMessage,
        details: errorDetails,
        type: errorType,
        suggestion: suggestion,
        showRetryButton: showRetryButton,
        showActionButton: showActionButton,
        actionButtonText: actionButtonText,
        actionButtonColor: actionButtonColor
      };
      
      if (hasValidAction) {
        this.errorDialog.action = options.action;
      }
      
      if (options.showActionButton && !hasValidAction) {
        console.warn('showActionButton is true but no valid action handler was provided');
      }
    },

    clearError() {
      this.fileError = '';
      this.errorDialog.show = false;
    },

    validateForm() {
      let isValid = true;

      if (!this.reportDate) {
        this.dateError = 'Please select a report month';
        isValid = false;
      }

      if (!this.uploadFile) {
        this.fileError = 'Please select a file to upload';
        isValid = false;
      }

      return isValid;
    },

    handleApiError(error) {
      console.error('Upload error:', error);
      this.$emit('upload-error', error);
    },

    retryUpload() {
      this.uploadNewFile();
    },
    
    downloadTemplate() {
      console.log('Downloading template file');
      
      const templateUrl = process.env.VUE_APP_TEMPLATE_URL || '/templates/loan_upload_template.xlsx';
      
      try {
        window.open(templateUrl, '_blank');
      } catch (e) {
        console.error('Failed to open template URL:', e);
        
        const link = document.createElement('a');
        link.href = templateUrl;
        link.download = 'loan_upload_template.xlsx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      
      this.showNotification('Template download initiated', 'info');
    },

    simulateProgressUpdates() {
      // Reset progress
      this.uploadProgress = 0;
      
      // Simulate progress while upload is in progress
      this.progressInterval = setInterval(() => {
        if (this.uploadProgress < 90) {
          // Increment more slowly as we approach 90%
          const increment = this.uploadProgress < 50 ? 5 : 2;
          this.uploadProgress += increment;
        }
      }, 300);
    },

    async uploadNewFile() {
      if (!this.validateForm()) {
        return;
      }

      this.dataLoading = true;
      this.clearError();
      this.simulateProgressUpdates();

      try {
        const formData = new FormData();
        formData.append("file", this.uploadFile);
        formData.append("year", moment(this.reportDate).format("YYYY"));
        
        formData.append("month", moment(this.reportDate).locale('en').format("MMMM"));
        
        formData.append("upload_user", (process.env.VUE_APP_ENV === "dev") ? 
          "System" : 
          JSON.parse(localStorage.getItem("user")).email
        );

        const result = await creditScoringService.uploadFile(formData);
        
        // Upload is complete
        this.uploadProgress = 100;
        clearInterval(this.progressInterval);
        
        if (result.result) {
          this.uploadResponse = result.value; // Store the response for later use
          this.generateReportDialog = true; // Show the dialog asking to generate report
          return true;
        } else {
          throw result.error;
        }
      } catch (error) {
        // Clear interval and reset progress on error
        clearInterval(this.progressInterval);
        this.uploadProgress = 0;
        this.handleApiError(error);
        return false;
      } finally {
        this.dataLoading = false;
      }
    },

    closeGenerateReportDialog() {
      this.generateReportDialog = false;
      this.$emit('upload-success', this.uploadResponse);
      this.closeDialog();
    },

    async generateReport() {
      if (!this.uploadResponse || !this.uploadResponse.uploadId) {
        console.error('Missing uploadId in response:', this.uploadResponse);
        this.showNotification('Error: Upload ID not available', 'error');
        this.generateReportDialog = false;
        return;
      }

      this.generating = true;
      try {
        console.log('Generating report for upload ID:', this.uploadResponse.uploadId);
        
        const response = await creditScoringService.calculateScores(this.uploadResponse.uploadId);
        
        if (response.result) {
          this.showNotification('Credit score report generated successfully', 'success');
          
          // Close the dialog before navigation
          this.generateReportDialog = false;
          
          // Emit event with report data - will trigger navigation in parent component
          this.$emit('report-generated', response.value);
          
          return true;
        } else {
          // Handle 409 error specifically
          if (response.status === 409 && response.data && response.data.existingReportId) {
            const reportId = response.data.existingReportId;
            
            // Only enable the action button if we have a valid reportId
            const hasReportId = !!reportId;
            
            this.showErrorDialog({
              title: 'Report Already Exists',
              message: 'A report already exists for this month and year.',
              type: 'info',
              showRetryButton: false,
              showActionButton: hasReportId,
              actionButtonText: 'View Existing Report',
              actionButtonColor: 'primary',
              action: () => { 
                this.$emit('view-existing-report', reportId);
              }
            });
          } else {
            throw response.error;
          }
        }
      } catch (error) {
        console.error('Report generation error:', error);
        this.handleApiError(error);
        return false;
      } finally {
        this.generating = false;
        this.generateReportDialog = false;
        
        // Emit upload success to close the upload dialog
        this.$emit('upload-success', this.uploadResponse);
        this.closeDialog();
      }
    },

    showNotification(text, color = 'info') {
      this.snackbar = {
        show: true,
        text,
        color
      };
    },

    closeDialog() {
      this.clearError();
      this.uploadFile = null;
      this.uploadFileName = '';
      this.uploadProgress = 0;
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
      }
      this.formOpened = false;
    }
  },

  watch: {
    visible(newValue) {
      if (!newValue) {
        this.uploadFile = null;
        this.uploadFileName = '';
        this.clearError();
        this.uploadProgress = 0;
        if (this.progressInterval) {
          clearInterval(this.progressInterval);
        }
      }
    },

    reportDate() {
      this.dateError = '';
    }
  },
  
  beforeDestroy() {
    // Clean up interval if component is destroyed
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
  }
}
</script>

<style scoped>
.warning-text {
  color: red;
  margin-bottom: 20px;
}

.error-alert {
  margin-bottom: 16px;
}

.error-title {
  font-weight: 600;
  margin-bottom: 8px;
}

.error-details {
  margin-top: 8px;
  font-size: 0.9em;
}

.error-details :deep(.error-list) {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 24px;
}

.error-details :deep(.error-list li) {
  margin-bottom: 4px;
}

.v-text-field {
  margin-top: 16px;
}
</style>